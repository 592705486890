export const GET_EVENTS = '/v1/admin/events';
export const GET_PRINTJOB_TRANSACTIONS = '/v1/admin/print_job_transactions';
export const GET_PACKAGE_TRANSACTIONS = '/v1/admin/package_transactions';
export const GET_RECALCULATION_LOG = '/v1/admin/recalculations';
export const GET_INVOICE_TRANSACTIONS = '/v1/admin/invoice_transactions';
export const GET_TRANSACTION_TABLES = '/v1/admin/admin_tables';
export const GET_INVOICES = '/v2/summary/invoices';
export const DOWNLOAD_INVOICE_XLSX = '/v1/print_house_summary/export';
export const DOWNLOAD_RECALCULATION_REPORT = '/v1/admin/recalculations/report/csv';
export const GET_GCW_INVOICE_SUMMARY = '/v1/gcworkflow/invoice/summary';
export const DOWNLOAD_GCW_INVOICE_XLSX = '/v1/gcworkflow/invoice/summary/xlsx';
