import { createApi } from '@reduxjs/toolkit/query/react';

import { fetchBaseQueryWrapper } from 'core/Helpers/baseQuery';
import { AGREEMENT_MANAGEMENT_URL } from 'config/DomainsConfig';
import {
  ACTIVATE_PRESET_PRICING_MODELS,
  ACTIVATE_PRICING_MODEL,
  DEACTIVATE_PRICING_MODEL,
  DISCARD_PRICING_MODEL,
  GET_AGREEMENT_PRICING_CATEGORY_LIST,
  GET_CONFIGURATION_BY_AGREEMENT_VERSIONS,
  GET_LATEST_AGREEMENT_VERSION,
  GET_PRICING_MODELS,
} from 'config/AgreementManagementEndpoints';

import {
  CurrentAgreementVersions,
  GetPricingModelConfigRequest,
  PriceListUid,
  PricingCategoriesResponseData,
  PricingModel,
  PricingModelActivationRequest,
  PricingModelConfiguration,
  PricingModelsRequest,
  PrintHousePartyType,
} from './Types';

export const AgreementManagementApi = createApi({
  reducerPath: 'AgreementManagementApi',
  baseQuery: fetchBaseQueryWrapper(AGREEMENT_MANAGEMENT_URL),
  tagTypes: ['pricingConfigs', 'agreementVersions', 'pricingDetails'],
  endpoints: (builder) => ({
    getLatestAgreementVersions: builder.query<
      CurrentAgreementVersions,
      { partyType: PrintHousePartyType; partyUid: string }
    >({
      query: (data) => ({
        url: GET_LATEST_AGREEMENT_VERSION,
        method: 'POST',
        body: data,
      }),
      transformResponse: (resp: { data: CurrentAgreementVersions }) => {
        return resp.data || {};
      },
      providesTags: ['agreementVersions'],
    }),
    getPricingModelConfig: builder.query<PricingModelConfiguration[], GetPricingModelConfigRequest>({
      query: (data) => ({
        url: `${GET_CONFIGURATION_BY_AGREEMENT_VERSIONS}/${data.versionId}`,
        method: 'POST',
      }),
      transformResponse: (resp: { data: { model: PricingModelConfiguration[] } }) => {
        return resp.data?.model || [];
      },
      providesTags: ['pricingConfigs'],
    }),
    activatePricingModel: builder.mutation<PricingModelConfiguration, PricingModelActivationRequest>({
      query: (data) => ({
        url: ACTIVATE_PRICING_MODEL,
        method: 'POST',
        body: data,
      }),
      transformResponse: (resp: { data: { configuration: PricingModelConfiguration } }) => {
        return resp.data?.configuration;
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            AgreementManagementApi.util.updateQueryData('getPricingModelConfig', arg.listingArgs, (draft) => {
              draft.push(data); // add record in cached list
            }),
          );
        } catch {}
      },
    }),
    deactivatePricingModel: builder.mutation<
      PricingModelConfiguration,
      { configurationUuid: string; partyUid: string }
    >({
      query: ({ configurationUuid }) => ({
        url: DEACTIVATE_PRICING_MODEL,
        method: 'POST',
        body: { configurationUuid },
      }),
      transformResponse: (resp: { data: { configuration: PricingModelConfiguration } }) => {
        return resp.data?.configuration;
      },
      invalidatesTags: (result) => (result ? ['pricingConfigs'] : []), // only invalidate on success
    }),
    discardDraftChange: builder.mutation<null, { partyUid: string; configuration: PricingModelConfiguration }>({
      query: ({ configuration }) => ({
        url: DISCARD_PRICING_MODEL,
        method: 'POST',
        body: { configurationUuid: configuration.configurationUuid },
      }),
      invalidatesTags: (result) => (result ? ['pricingConfigs'] : []), // only invalidate on success
    }),
    activatePresetPricingModels: builder.mutation<
      PricingModelConfiguration[],
      { partyUid: string; preset: string; }
    >({
      query: (data) => ({
        url: ACTIVATE_PRESET_PRICING_MODELS,
        method: 'POST',
        body: data,
      }),
      transformResponse: (resp: { data: { configuration: PricingModelConfiguration[] } }) => {
        return resp.data?.configuration;
      },
      invalidatesTags: (result) => (result ? ['pricingConfigs'] : []), // only invalidate on success
    }),
    getPricingModels: builder.query<PricingModel[], void>({
      query: () => ({
        url: GET_PRICING_MODELS,
        method: 'POST',
        body: { status: 'all' },
      }),
      transformResponse: (resp: { data: { model: PricingModel[] } }) => {
        return resp.data?.model || [];
      },
      keepUnusedDataFor: 86400, // one day
    }),
    getPricingModelsWithFilters: builder.query<PricingModel[], PricingModelsRequest>({
      query: (data) => ({
        url: GET_PRICING_MODELS,
        method: 'POST',
        body: data,
      }),
      transformResponse: (resp: { data: { model: PricingModel[] } }) => {
        return resp.data?.model || [];
      },
    }),
    getPricingCategories: builder.query<PricingCategoriesResponseData, { pricingModelUid?: string }>({
      query: (data) => ({
        url: GET_AGREEMENT_PRICING_CATEGORY_LIST,
        method: 'POST',
        body: data,
      }),
      transformResponse: (resp: { data: PricingCategoriesResponseData }) => {
        return resp.data;
      },
    }),
  }),
});

export const {
  useGetLatestAgreementVersionsQuery,
  useGetPricingModelConfigQuery,
  useActivatePricingModelMutation,
  useActivatePresetPricingModelsMutation,
  useDeactivatePricingModelMutation,
  useDiscardDraftChangeMutation,
  useGetPricingModelsQuery,
  useGetPricingModelsWithFiltersQuery,
  useGetPricingCategoriesQuery,
} = AgreementManagementApi;
