import { createApi } from '@reduxjs/toolkit/query/react';

import { GELATO_NETWORK_URL } from 'config/DomainsConfig';
import { PRODUCTS_CATALOGUE } from 'config/GelatoNetworkEndPoints';
import { fetchBaseQueryWrapper } from 'core/Helpers/baseQuery';

import { ProductsModelListResponse } from './Types';

export const ProductsListAPI = createApi({
  reducerPath: 'ProductsModelListAPI',
  baseQuery: fetchBaseQueryWrapper(GELATO_NETWORK_URL),
  endpoints: (builder) => ({
    getProductsModelList: builder.query<ProductsModelListResponse, {}>({
      query: (data) => ({
        url: PRODUCTS_CATALOGUE,
        method: 'POST',
        body: data,
      }),
      keepUnusedDataFor: 86400, // one day
    }),
  }),
});

export const { useGetProductsModelListQuery } = ProductsListAPI;
