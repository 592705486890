import { createSlice } from '@reduxjs/toolkit';

import { IState } from './Types';
import { WorkflowConfigurationApi } from './WorkflowConfigurationApi';

const initialState: IState = {
  workflowTypes: [],
  data: {},
  productModels: [],
};

const WorkflowConfigurationSlice = createSlice({
  name: 'WorkflowConfigurationSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(WorkflowConfigurationApi.endpoints.getWorkflowTypes.matchFulfilled, (state, { payload }) => {
      state.workflowTypes = payload;
    });
    builder.addMatcher(
      WorkflowConfigurationApi.endpoints.getWorkflowConfig.matchFulfilled,
      (state, { payload, meta }) => {
        const printHouse = meta.arg.originalArgs;
        state.data[printHouse] = payload;
      },
    );
    builder.addMatcher(
      WorkflowConfigurationApi.endpoints.addWorkflowCustomization.matchFulfilled,
      (state, { payload, meta }) => {
        const { printHouseUid } = meta.arg.originalArgs;
        state.data[printHouseUid] = {
          ...state.data[printHouseUid],
          customization: [...(state.data[printHouseUid]?.customization || []), payload],
        };
      },
    );
    builder.addMatcher(
      WorkflowConfigurationApi.endpoints.removeWorkflowCustomization.matchFulfilled,
      (state, { meta }) => {
        const { printHouseUid, productModelUid } = meta.arg.originalArgs;
        state.data[printHouseUid] = {
          ...state.data[printHouseUid],
          customization: state.data[printHouseUid]?.customization.filter((c) => c.productModelUid !== productModelUid),
        };
      },
    );
    builder.addMatcher(
      WorkflowConfigurationApi.endpoints.changeDefaultWorkflow.matchFulfilled,
      (state, { payload, meta }) => {
        const { printHouseUid } = meta.arg.originalArgs;
        state.data[printHouseUid] = payload;
      },
    );
    builder.addMatcher(
      WorkflowConfigurationApi.endpoints.getTechnicalProductModels.matchFulfilled,
      (state, { payload }) => {
        state.productModels = payload;
      },
    );
  },
});

export default WorkflowConfigurationSlice.reducer;
